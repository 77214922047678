<template>

    <section class="section-hero ">
        <div id="carouselExampleFade" class="carousel slide carousel-fade">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="@/assets/img1.jpg" class="d-block w-100" alt="Ana García de Hernández con sus hijas">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img2.jpg" class="d-block w-100" alt="Ana García de Hernández con sus hijas">
                </div>

            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        
    </section>

    <section class="about-me" id="about-me">
        <div class="container">
            <div class="row p-5">
                <h3 class="mt-5 mb-5 text-center display-3 display-sm-5">Por un futuro de Honduras mejor</h3>

                <div class="d-flex justify-content-center">
                    <blockquote class="twitter-tweet" data-media-max-width="560"><p lang="es" dir="ltr">Mi mensaje esta tarde cuando anuncie que buscare la candidatura a la Presidencia de la República por el Partido Nacional. Es una lucha ante la injusticia y en búsqueda de la justicia para el pueblo Hondureño. Acompáñenme mañana a las 10:00 am en el Comite Central. <a href="https://t.co/6VSJRLWGTO">pic.twitter.com/6VSJRLWGTO</a></p>&mdash; Ana García de Hernández (@anagarciacarias) <a href="https://twitter.com/anagarciacarias/status/1767767974822215881?ref_src=twsrc%5Etfw">March 13, 2024</a></blockquote> 
                </div>
            </div>

        </div>
    </section>

</template>

<script>
export default {
    name: 'BodyApp',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
