<template>
  <HeaderApp/>

  <BodyApp />
  
  <FooterApp/>

</template>

<script>
import BodyApp from './components/BodyApp.vue';
import HeaderApp from './components/HeaderApp.vue';
import FooterApp from './components/FooterApp.vue';

export default {
  name: 'App',
  components: {
    BodyApp,
    HeaderApp,
    FooterApp,
  }
}
</script>


