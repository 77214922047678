<template>
  <header>
    <nav class="main-nav container-fluid nav-s">
      <div
        id="main-nav"
        class="row d-flex justify-content-between align-items-center"
      >
        <div
          id="logo"
          onclick="home()"
          class="col-9 col-lg-3 d-flex align-items-center justify-content-center logo"
        >
          <!-- <img id="logo-nav" class="me-3 col-6" style="width: 200px;" src="/img/LogoAMpeq.png" alt=""> -->
          <div class="tracking-in-expand d-flex">
            <p class="">Ana</p>
            &nbsp;
            <p class="color-first">Presidenta</p>
          </div>
        </div>
        
        <div
          class="col-6 d-flex align-items-center justify-content-center center-nav"
        >
          <a
            class="main-nav-link text-center"
            href="http://registrate.anagarciacarias.com/"
            onclick="closef()"
            >¡Se Parte!</a
          >
          
          <a class="circulo element" href="http://registrate.anagarciacarias.com/" ></a>
          <!-- <a class="main-nav-link me-4" href="#sevicios-antes" onclick="closef()">SERVICIOS</a>
                    <a class="main-nav-link me-4" href="#proyectos" onclick="closef()">PROYECTOS</a>
                    <a class="main-nav-link me-4" href="#contact" onclick="closef()">CONTACTO</a> -->
        </div>

        <div class="col-3 d-flex justify-content-center center-nav final-nav">
          <div class="iconos-apps">
            <a href="https://www.facebook.com/AnaGarciadeHernandez" target="_blank" class="me-3 fa-brands fa-facebook"></a>
          </div>
          <div class="iconos-apps">
            <a href="https://www.tiktok.com/@anagarciacarias?lang=es" target="_blank" class="me-3 fa-brands fa-tiktok"></a>
          </div>
          <div class="iconos-apps">
            <a href="https://twitter.com/anagarciacarias?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank" class="me-3 fa-brands fa-x-twitter"></a>
          </div>
          <div class="iconos-apps">
            <a href="https://www.instagram.com/anagarciahn/?hl=es" target="_blank" class="fa-brands fa-instagram"></a>
          </div>
        </div>
        <button
          class="col-3 btn-mobile-nav"
          onclick="openNav()"
          id="btnMobileNav"
        >
          <i class="fa-solid fa-bars"></i>
        </button>
      </div>
    </nav>
    <!-- <div class="franja"></div> -->
  </header>
</template>

<script>
export default {
  data() {
      return {
        text: ''
      }
    },
    methods: {
      redireccionar() {
        console.log('Hola');
      }
    },
  // Lógica de Vue para el componente Header
  mounted() {
    let mainNav = document.querySelector(".main-nav");
    let centerNav = document.querySelector(".center-nav");
    let finalNav = document.querySelector(".final-nav");

    (mainNav.innerHTML += `<div id="bot-nav" class="row d-none">\n${centerNav.innerHTML}    \n<div class="col d-flex justify-content-center ">\n${finalNav.innerHTML} \n</div>  \n \n</div>`),
      
    
    
    window.addEventListener("resize", function () {
    
        //     let e = document.getElementById("bot-nav");
        // window.innerWidth > 992 && open == !1
        //   ? document.getElementById("bot-nav").classList.add("d-none")
        //   : (document.getElementById("bot-nav").classList.remove("d-none"),
        //     (e.style.backgroundColor = "#2d2e32"),
        //     e.classList.add("d-none"));
            let e = document.getElementById("bot-nav");
         (document.getElementById("bot-nav").classList.remove("d-none"),
            (e.style.backgroundColor = "#2d2e32"),
            e.classList.add("d-none"));
     
        

      });
    var open = !1;
  },
};
</script>

<style scoped>
/* Estilos específicos del encabezado */
.tracking-in-expand {
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;

  font-size: 30px;
  color: rgb(0, 145, 255);
  /* color: rgb(48, 97, 255); */
}

/* ----------------------------------------------
* Generated by Animista on 2024-4-5 18:52:19
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.color-first {
  color: rgb(122, 179, 255);
}


.circulo {
  display: inline-block;
  position: absolute;
  z-index: -100;
  /* Valor más bajo para enviar hacia atrás */
  border-radius: 50%;
  /* Hace que el borde sea redondo, creando un círculo */
  /* background-color: rgb(98, 184, 255); */
  background-color: rgb(0, 145, 255);

  /* Define el color de fondo del círculo */
  color: white;
  /* Color del texto dentro del círculo */
  padding: 10px;
  /* Espaciado interno para el texto */
  width: 150px;
  /* Ancho del círculo */
  height: 150px;
  /* Altura del círculo */
  text-align: center;
  /* Centra el texto vertical y horizontalmente */
  line-height: 30px;
  /* Ajusta la altura de línea para centrar verticalmente el texto */
  cursor: pointer;
}
.circulo:hover {
  height: 250px;
  width: 250px;
  margin: 0 auto;
  background-color: rgb(45, 160, 255);
  animation-name: stretch;
  animation-duration: 1.5s; 
  animation-timing-function: ease-out; 
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}
@media only screen and (max-width: 992px) {
  .circulo {
    display: none;
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.6);
    background-color: rgb(0, 157, 214);
    border-radius: 100%;
  }
  50% {
    background-color: rgb(0, 145, 255);
  }
  100% {
    transform: scale(0.7);
    background-color: rgb(5, 163, 255);
  }
}
.main-nav-link {
  color: white;
  font-size: 20px;
}

.iconos-apps{
  font-size: 20px;
}

.iconos-apps a {
  color: black; /* Color negro por defecto */
  text-decoration: none;
}
.iconos-apps a:hover {
  color: rgb(78, 190, 255); /* Color negro por defecto */
}

/* Media query para dispositivos móviles */
@media only screen and (max-width: 768px) {
  .iconos-apps a {
    color: white; /* Cambiar color a blanco en dispositivos móviles */
  }
}


</style>

