<template>
      <footer class="footer">
      <!-- <img src="img/back.png" alt="" class="back2 "> -->
      <div class="container">
          <div class="row">
              <div class="col-md-3">
  
                  <ul class="list-unstyled">

                  </ul>
              </div>
              <div class="col-md-6">
                  <!-- <h4>Address</h4> -->
                  <!-- <p>Tegucigalpa, Honduras</p> -->
              </div>
              <div class="col-md-3">

              </div>
          </div>
          <div class="row">
              <div class="col-md-12 text-center">
                  <p>&copy; 2023 ANAPRESIDENTA. All rights reserved.</p>
              </div>
          </div>
      </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>